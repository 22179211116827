export interface TariffsState {
    data: FetchTariffsResponse;
    loading: boolean;
    error: null | string;
}

export interface FetchTariffsResponse {
    count: number;
    tariffs: ITariffs []
}
export interface ITariffs {
    id: number,
    name: string,
    price: string,
    price_type: 'day' | 'month',
    speed: string,
    information: string | null
}

export enum TariffsActionTypes {
    FETCH_TARIFFS = 'FETCH_TARIFFS',
    FETCH_TARIFFS_SUCCESS  = 'FETCH_TARIFFS_SUCCESS',
    FETCH_TARIFFS_ERROR = 'FETCH_TARIFFS_ERROR',
}

interface FetchTariffsAction {
    type: TariffsActionTypes.FETCH_TARIFFS
}

interface FetchTariffsSuccessAction {
    type: TariffsActionTypes.FETCH_TARIFFS_SUCCESS
    payload: FetchTariffsResponse
}

interface FetchTariffsErrorAction {
    type: TariffsActionTypes.FETCH_TARIFFS_ERROR
    payload: string
}

export type TariffsAction = FetchTariffsAction | FetchTariffsSuccessAction | FetchTariffsErrorAction;
