import React  from 'react';
import style from './PaymentModal.module.css'

const priceTypes = {
    day: 'ежедневное',
    month: 'ежемесячное',
    once: 'разовое'
}

export default function PaymentModal ({paymentsData}) {

    const data = Object.values(paymentsData)


    const headerData = ['Услуга', 'Периодичность списания', 'Дата списания', 'Цена', 'Количество','Сумма']

    return (
        <div className={style.root}>
            <div className={style.trContainer}>
                {headerData.map((item, index) => <div key={index}>{item}</div>)}
            </div>
            <div className={style.root}>{data.map((item, index) => {
                const priceType = priceTypes[item.PeriodType]

                return (
                    <div className={style.trContainer} key={index}>
                        <div className={style.info}>{item.Info}</div>
                        <div>{priceType}</div>
                        <div>{item.PayDate}</div>
                        <div>{item.PaySize}</div>
                        <div>{item.Count}</div>
                        <div>{item.PaySum}</div>
                    </div>
                )
            })}
            </div>
        </div>

    )
}