import React from 'react';

import style from './ListTvInfo.module.css'
import styles from '../../../Site/components/Tariffs/Tariffs.module.css';

export default function ListTvInfo () {

    const mainTextEnv = process.env.REACT_APP_BANDL1_MAIN
    const secondTextEnv = process.env.REACT_APP_BANDL1_SECOND

    return (
        <div className={style.root}>
            <div className={style.titleHeader}>
                Телевидение
            </div>
            <div className={style.bodyContainer}>
                <div className={style.title}>Об услуге:</div>
                <div className={style.textContainer}>
                    <div>Услуга позволяет просматривать телеканалы через приложение TB сервиса.</div>
                    <div className={style.textEnd}>Для работы сервиса необходимо подключение к интернету.</div>
                    <div>Возможности:</div>
                    <div className={style.possibilities}>
                        <li>Просмотр уже прошедших телепередач в любое время из архива за последние 7 дней.
                            Установка на паузу и просмотр с того же места.
                        </li>
                        <li>Просмотр разных каналов одновременно на пяти разных устройствах под одной учётной записью.</li>
                        <div>* &nbsp; за исключением ограничений , установленных правообладателем контента.</div>
                    </div>

                    <div>Важно</div>
                    <div className={style.important}>
                        <li>Для просмотра телеканалов на телевизоре без функции Смарт ТВ понадобится ТВ приставка.</li>
                        <li>Приложение 24часаТВ доступно на Смарт ТВ, ТВ-приставках, смартфонах, планшетах и в веб-браузере.</li>
                        <li>Оплата за сервис Интерактивного ТВ списывается с основного счета вместе
                            с платой за услугу Интернет.</li>
                    </div>
                </div>
            </div>
            <div className={styles.textContainer}>
                <div className={styles.mainText}>{mainTextEnv || 'Мы можем предоставить вам бесплатное подключение к пакету каналов "Бандл1" - 112 каналов'}</div>
                <div className={styles.secondText}>{secondTextEnv || '(количество может быть изменено сервисом, предоставляемым ООО "24часаТВ")'}</div>
            </div>
        </div>
    )
}
